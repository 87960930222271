import { RichTextBlock } from 'prismic-reactjs';
import { FixedObject, FluidObject } from 'gatsby-image';
import { ARGRole } from '../../components/BlogPost/PostContent/AuthorReviewer';

// reusable slice types object
export const COMMON_SLICE_TYPES = {
  TEXT: 'text',
  LIST: 'list_of_items',
  AUTHOR: 'author_reviewer_guest',
};

// util type
export type PrismicField = {
  html: string;
  raw: RichTextBlock[];
  text: string;
};

// reusable slice types structure
export type TextSlice = {
  id: string;
  slice_type: 'text';
  primary: { text: PrismicField };
};

export type ListSlice = {
  id: string;
  slice_type: 'list_of_items';
  primary: {
    list: PrismicField;
    marker: string;
  };
};

export type AuthorSlice = {
  id: string;
  slice_type: 'author_reviewer_guest';
  primary: {
    role: ARGRole;
    link: {
      document: {
        data: {
          name: PrismicField;
          bio: PrismicField;
          avatar: {
            fixed?: FixedObject;
            fluid: FluidObject;
            alt: string;
            url: string;
          };
        };
      };
    };
  };
};

import React from 'react';
import { Hidden } from '@material-ui/core';
import {
  Container,
  Divider,
  H2,
  H3,
  SeoProps,
} from '@tuunetech/tuune-components';
import { Article } from 'types';
import { Banner, BannerProps } from '../Banner';
import { Summary, SummaryProps } from '../Summary';
import { PostGrid } from '../PostGrid';
import { PostContent, PostContentProps } from '../PostContent';
import { Sidebar } from '../../Sidebar';
import { Share } from '../PostContent/Share';
import { SelectedArticles } from '../../SelectedArticles';
import { PostsList } from '../../PostsList';

type BlogPostLayoutProps = {
  bannerData: BannerProps;
  picks?: Article[];
  related?: Article[];
  seoData?: SeoProps;
} & PostContentProps &
  SummaryProps;

export const BlogPostLayout: React.FC<BlogPostLayoutProps> = ({
  bannerData,
  summary,
  postContent,
  picks,
  related,
  seoData,
}) => {
  return (
    <>
      <Banner {...bannerData} />
      <Summary summary={summary} />
      <PostGrid
        left={
          <>
            <PostContent postContent={postContent} />
            {seoData && <Share {...seoData} />}
          </>
        }
        right={
          picks &&
          picks.every(({ document }) => !!document) && (
            <Sidebar>
              <>
                <H3 align="left" color="primary">
                  Editor’s Picks
                </H3>
                <SelectedArticles articles={picks} />
                <Hidden smDown>
                  <Divider $verticalMargin={40} />
                </Hidden>
              </>
            </Sidebar>
          )
        }
      />
      {related && related.every(({ document }) => !!document) && (
        <Container>
          <H2 $verticalMargin={40} align="center">
            You may also like
          </H2>
          <PostsList
            posts={related.map(({ document }) => document)}
            count={0}
            page={0}
            path=""
          />
        </Container>
      )}
    </>
  );
};

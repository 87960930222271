import React, { ReactNode } from 'react';
import {
  GridContainer,
  GridItem,
  Container,
} from '@tuunetech/tuune-components';
import { LeftGridItem } from './styles';

type PostGridProps = {
  left: ReactNode;
  right?: ReactNode;
};

export const PostGrid: React.FC<PostGridProps> = ({ left, right }) => {
  return (
    <Container>
      <GridContainer>
        <LeftGridItem $offsetXl={2} $offsetLg={1} xl={6} lg={7} md={8} xs={12}>
          {left}
        </LeftGridItem>
        <GridItem md={4} xs={12}>
          {right}
        </GridItem>
      </GridContainer>
    </Container>
  );
};

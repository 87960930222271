import React, { ReactNode } from 'react';
import Img, { FixedObject } from 'gatsby-image';
import { Box } from '@material-ui/core';
import { Divider, H5, H6, Icon } from '@tuunetech/tuune-components';
import { Heading, PersonContainer, Content, AvatarWrapper } from './styles';

export enum ARGRole {
  Author = 'author',
  Reviewer = 'reviewer',
  Guest = 'guest',
  CoAuthor = 'co-author',
  Researcher = 'scientific researcher',
  Editor = 'editor',
}

export type AuthorReviewerData = {
  role: ARGRole;
  avatar: {
    alt: string;
    url: string;
    fixed: FixedObject;
    dimensions?: Record<string, unknown>;
  };
  name: string;
  about: ReactNode;
};

const getTitle = (role: ARGRole): string => {
  switch (role) {
    case ARGRole.Author:
      return 'Written by';
    case ARGRole.Guest:
      return 'Guest writer';
    case ARGRole.Reviewer:
      return 'Medically reviewed by';
    case ARGRole.CoAuthor:
      return 'Co-author';
    case ARGRole.Editor:
      return 'Editor';
    case ARGRole.Researcher:
      return 'Scientifically researched by';
  }
};

export const AuthorReviewer: React.FC<AuthorReviewerData> = ({
  role,
  avatar,
  name,
  about,
}) => {
  return (
    <>
      <Divider $verticalMargin={40} />
      <PersonContainer disableGutters fixed={false}>
        <Heading>
          <H5 color="primary">
            <>
              {role === ARGRole.Reviewer && (
                <Icon size={20} icon="checkCircled" />
              )}
              <span>{getTitle(role)}</span>
            </>
          </H5>
        </Heading>
        <Content>
          <AvatarWrapper>
            {avatar.dimensions ? (
              <img src={avatar.url} alt={avatar.alt} width={56} height={56} />
            ) : (
              <Img fixed={avatar.fixed} />
            )}
          </AvatarWrapper>
          <Box display="flex" flexDirection="column">
            <H6 color="textSecondary">{name}</H6>
            {about}
          </Box>
        </Content>
      </PersonContainer>
    </>
  );
};

import styled from 'styled-components';
import { GridItem } from '@tuunetech/tuune-components';

export const LeftGridItem = styled(GridItem)`
  padding-right: 8px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-right: 60px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-right: 134px;
  }
`;

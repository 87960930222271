import styled from 'styled-components';
import { Container } from '@tuunetech/tuune-components';

export const ReferencesContainer = styled(Container)<{ $isExpanded: boolean }>`
  li:nth-child(n + 4) {
    height: ${({ $isExpanded }) => ($isExpanded ? '' : '0')};
    opacity: ${({ $isExpanded }) => ($isExpanded ? '1' : '0')};
    margin-bottom: ${({ $isExpanded }) => ($isExpanded ? '' : '0')};
  }

  @media ${({ theme }) => theme.media.tablet} {
    display: block;
  }
`;

import React from 'react';
import { RichText } from 'prismic-reactjs';
import Img, { FixedObject, FluidObject } from 'gatsby-image';
import { Box } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { BODY2, Button, H5 } from '@tuunetech/tuune-components';
import {
  htmlSerializer,
  ListSlice,
  PrismicField,
  SliceRenderer,
} from 'utils/serialize';
import { Link, Quote, QuoteAuthor } from 'components/shared';

// local unique slice types object
export const LOCAL_SLICE_TYPES = {
  SUBHEADER: 'subheader',
  CTA: 'cta_button',
  REFS: 'references',
  QUOTE: 'quote',
  IMG: 'image',
  EMBED: 'embed',
};

export type SubheaderSlice = {
  id: string;
  slice_type: 'subheader';
  primary: { subheader: PrismicField };
};

export type CTASlice = {
  id: string;
  slice_type: 'cta_button';
  primary: {
    cta_button_text: string;
    cta_link: {
      url: string;
      document: {
        url: string;
      };
    };
  };
};

export type ReferencesSlice = {
  id: string;
  slice_type: 'references';
  primary: Partial<ListSlice['primary']>;
};

export type QuoteSlice = {
  id: string;
  slice_type: 'quote';
  primary: {
    author: PrismicField;
    quote: PrismicField;
  };
};

export type ImageSlice = {
  id: string;
  slice_type: 'image';
  primary: {
    caption: PrismicField;
    image: {
      url: string;
      fixed: FixedObject;
      fluid: FluidObject;
    };
  };
};

export type EmbedSlice = {
  id: string;
  slice_type: 'embed';
  primary: {
    embed_link: {
      title?: string;
      embed_url: string;
    };
  };
};

// local slice renderers
export const renderSubheader: SliceRenderer<SubheaderSlice> = ({
  id,
  primary,
}) => {
  return (
    <RichText
      key={id}
      render={primary.subheader.raw}
      htmlSerializer={htmlSerializer}
    />
  );
};

export const renderCTA: SliceRenderer<CTASlice> = ({ id, primary }) => {
  const ctaURL = primary.cta_link.url;
  const resultUrl = ctaURL
    ? ctaURL.includes('undefined')
      ? primary.cta_link.document.url
      : ctaURL
    : '';
  return (
    <Link key={id} to={resultUrl} target="_blank" underline="none">
      <Button>{primary.cta_button_text}</Button>
    </Link>
  );
};

export const renderQuote: SliceRenderer<QuoteSlice> = ({ id, primary }) => {
  return (
    <React.Fragment key={id}>
      <Quote>
        <RichText render={primary.quote.raw} />
      </Quote>
      {primary.author && (
        <QuoteAuthor>
          <RichText render={primary.author.raw} />
        </QuoteAuthor>
      )}
    </React.Fragment>
  );
};

export const renderImage: SliceRenderer<ImageSlice> = ({ id, primary }) => {
  return (
    <Box display="block" marginY="15px" key={id}>
      <Img fluid={primary.image.fluid} />
      {primary.caption.text && (
        <BODY2>{RichText.asText(primary.caption.raw)}</BODY2>
      )}
    </Box>
  );
};

export const renderEmbed: SliceRenderer<EmbedSlice> = ({ id, primary }) => {
  return (
    <Box marginY="15px" key={id} maxWidth="100%" display="flex" flexWrap="wrap">
      {primary.embed_link.title && (
        <H5 align="left" $marginBottom={10} color="primary">
          {primary.embed_link.title}
        </H5>
      )}
      <ReactPlayer key={id} url={primary.embed_link.embed_url} />
    </Box>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import { RichText } from 'prismic-reactjs';
import { graphql, PageProps } from 'gatsby';
import { Post, SEODStaticData } from 'types';
import { BlogPostLayout } from 'components/BlogPost';
import { Layout } from 'components/shared';
import { withPreview } from 'gatsby-source-prismic';
import { AuthorSlice } from 'utils/serialize';

import { constructSEO } from 'utils/seo';

export type BlogDataProp = {
  prismicBlogPost: Post;
  SEO: SEODStaticData;
};

const BlogPost: React.FC<PageProps<BlogDataProp>> = ({ data, location }) => {
  const [author, setAuthor] = useState<string>('');
  const postData = useMemo(() => data.prismicBlogPost.data || {}, [data]);

  const seoData = useMemo(
    () => constructSEO(postData, data.SEO, location.pathname),
    [postData, data.SEO, location.pathname],
  );

  useEffect(() => {
    if (postData.body) {
      const authors = Object.values(postData.body).filter(
        ({ slice_type }) => slice_type === 'author_reviewer_guest',
      ) as AuthorSlice[];
      const authorSlice = authors.find(
        ({ primary }) => primary.role === 'author',
      );
      if (authorSlice?.primary.link.document) {
        const author = authorSlice.primary.link.document.data;
        setAuthor(RichText.asText(author?.name.raw));
      }
    }
  }, [postData]);

  const bannerData = useMemo(() => {
    return {
      image: postData.hero_image?.fluid,
      title: postData.title?.raw,
      date: postData.release_date || '',
      author: author,
      isReviewed: postData.is_medically_reviewed,
      color: postData.hero_color,
    };
  }, [postData, author]);

  return (
    <Layout path={location.pathname} seoData={seoData}>
      <BlogPostLayout
        bannerData={bannerData}
        summary={{
          standFirst: postData.stand_first,
          takeaways: postData.takeaways,
          slices: postData.body1,
        }}
        postContent={postData.body}
        picks={postData.picks?.map(({ article }) => article)}
        related={postData.related?.map(({ article }) => article)}
        seoData={seoData}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    prismicBlogPost(id: { eq: $id }) {
      _previewable
      url
      ...PostHeroData
      ...SummaryData
      ...PostContentData
      ...PrismicSeoData
      ...RelatedPostsData
    }

    SEO: site {
      siteMetadata {
        description
        siteUrl
        title
        twitterUsername
      }
    }
  }
`;

export default withPreview(BlogPost);

import styled from 'styled-components';
import { Container, H3, H5, BODY1 } from '@tuunetech/tuune-components';

export const TintedContainer = styled(Container)`
  background-color: ${({ theme }) => theme.palette.background.light};
`;

export const AvatarWrapper = styled.div`
  border-radius: 50%;
  height: 102px;
  width: 102px;
  min-width: 102px;
  min-height: 102px;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const DocBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: 0 auto 33px;
`;

export const SummaryBox = styled.div`
  & + & {
    border-top: 1px solid ${({ theme }) => theme.palette.border.light};
    padding-top: 49px;
    margin-top: 57px;
  }
`;

export const SummaryContainer = styled(Container)`
  padding: 45px 0 50px;

  ${H5} {
    margin-top: 26px;
  }

  ${H3} {
    margin: 30px 0 15px;
  }

  ${BODY1} {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 78px 0 96px;

    ${DocBox} {
      margin: 0 0 33px;
    }
  }
`;

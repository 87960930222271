import React, { useContext } from 'react';
import {
  BODY2,
  Divider,
  GridContainer,
  GridItem,
  Icon,
  SeoProps,
} from '@tuunetech/tuune-components';
import { ThemeContext } from 'styled-components';
import { Box } from '@material-ui/core';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import { ShareContainer } from './styles';

export const Share: React.FC<SeoProps> = ({
  url,
  title,
  description,
  twitter,
}) => {
  const themeContext = useContext(ThemeContext);

  return url ? (
    <>
      <Divider $verticalMargin={40} />
      <ShareContainer disableGutters>
        <GridContainer alignItems="center">
          <GridItem sm={1} xs={12}>
            <BODY2 color="primary" align="left">
              {'Share'.toLocaleUpperCase()}
            </BODY2>
          </GridItem>
          <GridItem xl={5} lg={4} md={5} sm={7} xs={12}>
            <Box display="flex" justifyContent="space-evenly">
              {twitter && (
                <TwitterShareButton
                  url={url}
                  title={twitter.title}
                  via={twitter.site?.replace('@', '')}
                >
                  <Icon
                    icon="twitter"
                    color={themeContext.palette.primary.main}
                    size={30}
                  />
                </TwitterShareButton>
              )}
              <FacebookShareButton url={url} quote={title}>
                <Icon
                  icon="facebook"
                  color={themeContext.palette.primary.main}
                  size={30}
                />
              </FacebookShareButton>
              <LinkedinShareButton
                url={url}
                title={title}
                summary={description}
              >
                <Icon
                  icon="linkedin"
                  color={themeContext.palette.primary.main}
                  size={30}
                />
              </LinkedinShareButton>
            </Box>
          </GridItem>
        </GridContainer>
      </ShareContainer>
      <Divider $verticalMargin={40} />
    </>
  ) : null;
};

import React, { useState } from 'react';
import { Link } from 'components/shared';
import { Divider, H5 } from '@tuunetech/tuune-components';
import { ReferencesContainer } from './styles';

type ReferencesProps = {
  refs: number;
};

export const References: React.FC<ReferencesProps> = ({ refs, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return refs > 0 ? (
    <>
      <Divider $marginBottom={40} />
      <ReferencesContainer
        $isExpanded={isExpanded}
        disableGutters
        fixed={false}
      >
        <H5 color="primary">References</H5>
        {children}
        {refs > 3 && (
          <Link to="" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? 'Read less' : 'Read more'}
          </Link>
        )}
      </ReferencesContainer>
    </>
  ) : null;
};

import React from 'react';
import { Elements, RichText } from 'prismic-reactjs';
import { PostDynamicZone } from 'types';
import {
  COMMON_SLICE_TYPES,
  getHtmlSerializer,
  renderList,
  renderText,
  ListSlice,
  TextSlice,
  AuthorSlice,
} from 'utils/serialize';
import { ContentContainer, ListWrapper } from './styles';
import { BODY2, Container, H2 } from '@tuunetech/tuune-components';
import {
  LOCAL_SLICE_TYPES,
  renderSubheader,
  renderCTA,
  CTASlice,
  ReferencesSlice,
  SubheaderSlice,
  renderQuote,
  QuoteSlice,
  renderImage,
  ImageSlice,
  renderEmbed,
  EmbedSlice,
} from '../util/serialize';
import { References } from './References';
import { AuthorReviewer } from './AuthorReviewer';

export type PostContentProps = {
  postContent: PostDynamicZone;
};

export const PostContent: React.FC<PostContentProps> = ({ postContent }) => {
  return (
    <ContentContainer disableGutters>
      {postContent.map(slice => {
        switch (slice.slice_type) {
          case COMMON_SLICE_TYPES.TEXT:
            return renderText(slice as TextSlice);
          case LOCAL_SLICE_TYPES.SUBHEADER:
            return renderSubheader(slice as SubheaderSlice);
          case LOCAL_SLICE_TYPES.CTA:
            return (
              <Container key={slice.id} disableGutters $verticalMargin={30}>
                {renderCTA(slice as CTASlice)}
              </Container>
            );
          case LOCAL_SLICE_TYPES.REFS:
            return (
              <References
                key={slice.id}
                refs={(slice as ReferencesSlice).primary.list?.raw.length || 0}
              >
                {renderList(
                  slice as ListSlice,
                  getHtmlSerializer({
                    [Elements.oListItem]: {
                      Component: BODY2,
                      props: { $marginBottom: 25 },
                    },
                  }),
                )}
              </References>
            );
          case COMMON_SLICE_TYPES.LIST:
            return (
              <ListWrapper
                key={slice.id}
                $marker={(slice as ListSlice).primary.marker}
              >
                {renderList(slice as ListSlice)}
              </ListWrapper>
            );
          case COMMON_SLICE_TYPES.AUTHOR:
            const author = (slice as AuthorSlice).primary;
            const authorData = author.link?.document?.data;
            return authorData ? (
              <AuthorReviewer
                key={slice.id}
                role={author.role}
                name={RichText.asText(authorData.name.raw)}
                about={
                  <RichText
                    render={authorData.bio.raw}
                    htmlSerializer={getHtmlSerializer({
                      [Elements.paragraph]: {
                        Component: BODY2,
                        props: { color: 'textSecondary' },
                      },
                    })}
                  />
                }
                avatar={authorData.avatar}
              />
            ) : null;
          case LOCAL_SLICE_TYPES.QUOTE:
            return renderQuote(slice as QuoteSlice);
          case LOCAL_SLICE_TYPES.IMG:
            return renderImage(slice as ImageSlice);
          case LOCAL_SLICE_TYPES.EMBED:
            return renderEmbed(slice as EmbedSlice);
          default:
            console.log(slice);
            return (
              <div key={slice.id}>
                <H2 color="error">!!! Unknown slice !!!</H2>
              </div>
            );
        }
      })}
    </ContentContainer>
  );
};

import styled from 'styled-components';
import { BODY1, Container, H2 } from '@tuunetech/tuune-components';
import { OListItem, PostImage, QuoteAuthor } from 'components/shared';

export const ListWrapper = styled.div<{ $marker: string }>`
  ${OListItem} {
    list-style-type: ${({ $marker }) => $marker};
  }
`;

export const ContentContainer = styled(Container)`
  ${BODY1} + ${BODY1} {
    margin-top: 20px;
  }

  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  ${H2} {
    margin: 70px auto 20px;
  }

  ${PostImage} {
    margin: 35px auto;
  }

  ${H2}, ${BODY1}, ${QuoteAuthor} {
    text-align: left;
  }

  @media ${({ theme }) => theme.media.tablet} {
    margin: 89px 14% 44px 0;
  }
`;

import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { Container } from '@tuunetech/tuune-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export const StyledBackground = styled(BackgroundImage)`
  width: 100%;
`;

export const StyledIcon = styled(CheckCircleIcon)``;

export const BannerContainer = styled(Container)<{ $color?: string }>`
  color: ${({ theme }) => theme.palette.text.primary};

  ${StyledIcon} {
    path {
      fill: ${({ theme }) => theme.palette.primary.main};
    }
    margin-right: 16px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 19% 0 17%;
    max-height: 40vh;
    display: flex;
    align-items: center;
    color: ${({ $color, theme }) => $color || theme.palette.text.primary};

    ${StyledIcon} {
      path {
        fill: ${({ theme, $color }) => $color || theme.palette.primary.main};
      }
    }
  }
`;

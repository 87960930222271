// reusable slice renderers
import React, { ReactNode } from 'react';
import { HTMLSerializer, RichText } from 'prismic-reactjs';
import { ListSlice, TextSlice } from './sliceTypes';
import { getHtmlSerializer } from './htmlSerializer';

export type SliceRenderer<S> = (
  slice: S,
  htmlSerializer?: HTMLSerializer<ReactNode>,
) => ReactNode;

export const renderText: SliceRenderer<TextSlice> = (
  { id, primary },
  htmlSerializer,
) => {
  return (
    <RichText
      key={id}
      render={primary.text.raw}
      htmlSerializer={htmlSerializer || getHtmlSerializer()}
    />
  );
};

export const renderList: SliceRenderer<ListSlice> = (
  { id, primary },
  htmlSerializer,
) => {
  return (
    <RichText
      key={id}
      render={primary.list.raw}
      htmlSerializer={htmlSerializer || getHtmlSerializer()}
    />
  );
};

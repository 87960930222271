import React, { useMemo } from 'react';
import Img from 'gatsby-image';
import { GridContainer, GridItem, H3, H5 } from '@tuunetech/tuune-components';
import { RichText } from 'prismic-reactjs';
import { SummaryDynamicZone } from 'types';
import { getHtmlSerializer, PrismicField } from 'utils/serialize';
import {
  DocBox,
  SummaryBox,
  SummaryContainer,
  TintedContainer,
  AvatarWrapper,
} from './styles';
import { CTASlice, renderCTA } from '../util/serialize';

export type SummaryProps = {
  summary: {
    slices: SummaryDynamicZone;
    standFirst: PrismicField;
    takeaways: PrismicField;
  };
};

export const Summary: React.FC<SummaryProps> = ({ summary }) => {
  const remappedSummary = useMemo(() => {
    const result: Record<'author_reviewer_guest' | string, any> = {};
    return summary.slices.reduce((acc, slice) => {
      acc[slice.slice_type] = slice;
      return acc;
    }, result);
  }, [summary]);

  const guest = useMemo(() => {
    if (remappedSummary.author_reviewer_guest?.primary.link.document) {
      return remappedSummary.author_reviewer_guest.primary.link.document.data;
    }
  }, [remappedSummary]);

  return (
    <TintedContainer disableGutters fixed={false}>
      <SummaryContainer>
        <GridContainer $disableNegativeMargins>
          <GridItem $offsetXl={2} $offsetMd={1} xl={1} md={2} xs={12}>
            {guest && (
              <DocBox>
                <AvatarWrapper>
                  {guest.avatar.dimensions ? (
                    <img
                      src={guest.avatar.url}
                      alt={guest.avatar.alt}
                      width={102}
                      height={102}
                    />
                  ) : (
                    <Img fixed={guest.avatar.fixed} />
                  )}
                </AvatarWrapper>
                <H5 align="center">{RichText.asText(guest.name.raw)}</H5>
                {/*{author.company && <BODY2>{author.company}</BODY2>}*/}
              </DocBox>
            )}
          </GridItem>

          <GridItem xl={5} lg={6} md={9}>
            {guest && (
              <SummaryBox>
                <H3 color="primary">Meet our guest</H3>
                <RichText
                  render={guest.bio.raw}
                  htmlSerializer={getHtmlSerializer()}
                />
              </SummaryBox>
            )}

            <SummaryBox>
              {summary.standFirst && (
                <RichText
                  render={summary.standFirst.raw}
                  htmlSerializer={getHtmlSerializer()}
                />
              )}
              <H3 color="primary">Key Takeaways</H3>
              {summary.takeaways && (
                <RichText
                  render={summary.takeaways.raw}
                  htmlSerializer={getHtmlSerializer()}
                />
              )}
              {remappedSummary.cta_button &&
                renderCTA(remappedSummary.cta_button as CTASlice)}
            </SummaryBox>
          </GridItem>
        </GridContainer>
      </SummaryContainer>
    </TintedContainer>
  );
};

import styled from 'styled-components';
import { BODY2, Container } from '@tuunetech/tuune-components';

export const ShareContainer = styled(Container)`
  ${BODY2} {
    margin-bottom: 29px;
  }

  @media ${({ theme }) => theme.media.mobile} {
    ${BODY2} {
      margin-bottom: 0;
    }
  }
`;

import styled from 'styled-components';
import { BODY2, Container, H5, H6 } from '@tuunetech/tuune-components';

export const Heading = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;

  ${BODY2} {
    order: -1;
    margin-bottom: 15px;
    width: 100%;
    text-transform: uppercase;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const PersonContainer = styled(Container)`
  ${H6} {
    margin-top: 21px;
    margin-bottom: 10px;
  }

  ${H5} {
    display: flex;
    margin-bottom: 25px;

    span:not(:first-child) {
      margin-left: 14px;
    }

    path {
      fill: ${({ theme }) => theme.palette.icon.main};
    }
  }

  @media ${({ theme }) => theme.media.tablet} {
    ${Heading} {
      width: auto;
      justify-content: space-between;

      ${BODY2} {
        order: 0;
        margin-bottom: 0;
        width: auto;
      }
    }

    ${Content} {
      justify-content: flex-start;
      flex-wrap: nowrap;
    }

    ${H6}, ${BODY2} {
      margin-left: 21px;
    }

    ${H6} {
      margin-top: 0;
    }
  }
`;

export const AvatarWrapper = styled.div`
  border-radius: 50%;
  height: 56px;
  width: 56px;
  min-width: 56px;
  min-height: 56px;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

import React, { useCallback } from 'react';
import { FluidObject } from 'gatsby-image';
import { Box, Hidden } from '@material-ui/core';
import {
  BODY2,
  GridContainer,
  GridItem,
  H5,
} from '@tuunetech/tuune-components';
import { Elements, RichText, RichTextBlock } from 'prismic-reactjs';
import moment from 'moment';
import { getHtmlSerializer } from 'utils/serialize';
import { BannerContainer, StyledBackground, StyledIcon } from './styles';

export type BannerProps = {
  image: FluidObject;
  title: RichTextBlock[];
  date: string;
  author: string;
  isReviewed: boolean;
  color?: string;
};

export const Banner: React.FC<BannerProps> = ({
  image,
  title,
  date,
  author,
  isReviewed,
  color,
}) => {
  const renderBannerData = useCallback(() => {
    return (
      <GridContainer justify="center">
        <GridItem md={7} lg={6}>
          {isReviewed && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <StyledIcon />
              <H5>Medically reviewed</H5>
            </Box>
          )}
          <RichText
            render={title}
            htmlSerializer={getHtmlSerializer({
              [Elements.heading1]: {
                props: { $marginBottom: 20, $marginTop: 27, align: 'center' },
              },
            })}
          />
          <BODY2 align="center">
            {moment(date).format('LL').toLocaleUpperCase()}
            {author ? `, by ${author}` : ''}
          </BODY2>
        </GridItem>
      </GridContainer>
    );
  }, [author, date, isReviewed, title]);

  return (
    <>
      <StyledBackground fluid={image}>
        <Hidden implementation="css" mdUp>
          <Box paddingTop="30%" paddingBottom="30%" />
        </Hidden>
        <Hidden smDown>
          <BannerContainer $color={color}>{renderBannerData()}</BannerContainer>
        </Hidden>
      </StyledBackground>
      <Hidden implementation="css" mdUp>
        <BannerContainer $paddingTop={35} $paddingBottom={40}>
          {renderBannerData()}
        </BannerContainer>
      </Hidden>
    </>
  );
};
